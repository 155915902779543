import React, { useContext } from 'react';

// import components
import CountryDropdown from './CountryDropdown';
import PropertyDropdown from './PropertyDropdown';

// import icons
import { RiSearch2Line } from 'react-icons/ri';

// import house context
import { HouseContext } from './HouseContext';

const Search = () => {
  const { handleClick } = useContext(HouseContext);

  return (
    <div className='px-4 py-2 max-w-[900px] mx-auto flex flex-col lg:flex-row justify-between gap-2 lg:gap-4 relative lg:-top-4 lg:shadow-lg bg-white lg:bg-transparent lg:backdrop-blur rounded-lg'>
      <div className="flex-1">
        <CountryDropdown className="h-16 w-full" />
      </div>
      <div className="flex-1">
        <PropertyDropdown className="h-16 w-full" />
      </div>
      <button
        onClick={handleClick}
        className='bg-brown-700 hover:bg-brown-600 transition flex-1 h-16 w-full rounded-lg flex justify-center items-center text-white text-lg py-2'
      >
        <RiSearch2Line className="mr-2" />
        Buscar
      </button>
    </div>
  );
};

export default Search;
