import React, { useState, useEffect, createContext } from 'react';

// import data
import { housesData } from '../data';

// create context
export const HouseContext = createContext();

const HouseContextProvider = ({ children }) => {
  const [houses, setHouses] = useState(housesData);
  const [country, setCountry] = useState('Ciudad (todos)');
  const [countries, setCountries] = useState([]);
  const [property, setProperty] = useState('Inmuebles (todos)');
  const [properties, setProperties] = useState([]);
  const [price, setPrice] = useState('Precio (todos)');
  const [loading, setLoading] = useState(false);

  // return all countries
  useEffect(() => {
    const allCountries = houses.map((house) => house.country);
    const uniqueCountries = ['Ciudad (todos)', ...new Set(allCountries)];
    setCountries(uniqueCountries);
  }, [houses]); // Añadido 'houses' como dependencia

  // return all properties
  useEffect(() => {
    const allProperties = houses.map((house) => house.type);
    const uniqueProperties = ['Inmuebles (todos)', ...new Set(allProperties)];
    setProperties(uniqueProperties);
  }, [houses]);

  const handleClick = () => {
    setLoading(true);
    const isDefault = (str) => str.split(' ').includes('(todos)');

    const minPrice = parseInt(price.split(' ')[0]);
    const maxPrice = parseInt(price.split(' ')[2]);

    console.log(maxPrice);

    const newHouses = housesData.filter((house) => {
      const housePrice = parseInt(house.price);

      // Lógica de filtrado
      if (
        house.country === country &&
        house.type === property &&
        housePrice >= minPrice &&
        housePrice <= maxPrice
      ) {
        return true;
      }

      if (isDefault(country) && isDefault(property) && isDefault(price)) {
        return true;
      }

      if (!isDefault(country) && isDefault(property) && isDefault(price)) {
        return house.country === country;
      }

      if (!isDefault(property) && isDefault(country) && isDefault(price)) {
        return house.type === property;
      }

      if (!isDefault(price) && isDefault(country) && isDefault(property)) {
        return housePrice >= minPrice && housePrice <= maxPrice;
      }

      if (!isDefault(country) && !isDefault(property) && isDefault(price)) {
        return house.country === country && house.type === property;
      }

      if (!isDefault(country) && isDefault(property) && !isDefault(price)) {
        return housePrice >= minPrice && housePrice <= maxPrice && house.country === country;
      }

      if (isDefault(country) && !isDefault(property) && !isDefault(price)) {
        return housePrice >= minPrice && housePrice <= maxPrice && house.type === property;
      }

      return false; // Asegúrate de que siempre haya un retorno
    });

    setTimeout(() => {
      setHouses(newHouses.length < 1 ? [] : newHouses);
      setLoading(false);
    }, 1000);
  };

  return (
    <HouseContext.Provider value={{
      country,
      setCountry,
      countries,
      property,
      setProperty,
      properties,
      price,
      setPrice,
      houses,
      handleClick,
      loading,
    }}>
      {children}
    </HouseContext.Provider>
  );
};

export default HouseContextProvider;
