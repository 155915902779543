import React from 'react';

// import components
import Banner from '../components/Banner';
import HouseList from '../components/HouseList';

const Home = () => {
  return <div className='min-h-[1800px]'>
    <title>Inmobiliaria Segian</title>
    <meta name='description' content='Somos una empresa joven que combina la experiencia de nuestro equipo profesional con modernos e innovadores proyectos.' />
    <meta name="keywords" content="departamentos, inmobiliaria, perú, venta, bienes raíces" />
    <Banner />
    <HouseList />
  </div>;
};

export default Home;
