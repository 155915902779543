import React, { useState } from "react";
import { RiSendPlane2Fill } from "react-icons/ri";
import { FaBuilding, FaPhone, FaClock } from "react-icons/fa";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, phone, email, message } = formData;
    const mailtoLink = `mailto:ventas@inmobiliariasegian.com.pe?subject=Contacto%20desde%20el%20sitio%20web&body=Nombre:%20${encodeURIComponent(
      name
    )}%0A%0ANúmero%20de%20celular:%20${encodeURIComponent(
      phone
    )}%0A%0ACorreo%20electrónico:%20${encodeURIComponent(
      email
    )}%0A%0AMensaje:%0A${encodeURIComponent(message)}`;

    window.location.href = mailtoLink;
  };

  return (
    <section className="bg-white" id="contact">
      <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8 lg:py-10">
        <div className="mb-4">
          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <p className="text-base font-semibold uppercase tracking-wide text-gray-800">
              Contacto
            </p>
            <h2 className="font-heading mb-4 font-semibold tracking-tight text-brown-700 text-3xl sm:text-5xl">
              Ponte en contacto con nosotros
            </h2>
          </div>
        </div>
        <div className="flex items-stretch justify-center">
          <div className="grid md:grid-cols-2">
            <div className="h-full pr-6">
              <p className="mt-3 mb-12 text-lg text-gray-600 dark:text-slate-600">
                No dudes en ponerte en contacto con nosotros para que pueda
                tener mayor información acerca de nuestros departamentos
                disponibles.
              </p>
              <ul className="mb-6 md:mb-0">
                <li className="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-brown-800 text-gray-50">
                    <FaBuilding className="h-6 w-6" />
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">
                      Oficina Principal
                    </h3>
                    <p className="text-gray-600">Oficina 1614 - Av. Pershing 790</p>
                    <p className="text-gray-600">
                      Magdalena del Mar
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-brown-800 text-gray-50">
                    <FaPhone className="h-6 w-6" />
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">
                      Contacto
                    </h3>
                    <p className="text-gray-600">(+51) 944 898 487</p>
                    <p className="text-gray-600">
                      ventas@inmobiliariasegian.com.pe
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-brown-800 text-gray-50">
                    <FaClock className="h-6 w-6" />
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">
                      Horarios de atención
                    </h3>
                    <p className="text-gray-600">Lunes a Sábados: 9am - 6pm</p>
                    <p className="text-gray-600">
                      Domingos y Feriados: 9am - 12pm
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            {/* Formulario de contacto */}
            <div className="card h-fit max-w-6xl p-5 md:p-4" id="form">
              <h2 className="mb-4 text-2xl font-semibold text-brown-700">
                Escríbenos un mensaje por correo electrónico
              </h2>
              <form id="contactForm" onSubmit={handleSubmit}>
                <div className="mb-6">
                  <div className="mx-0 mb-1 sm:mb-4">
                    <label
                      htmlFor="name"
                      className="pb-1 text-xs uppercase tracking-wider"
                    >
                      Nombre completo
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      autoComplete="given-name"
                      placeholder="Ingresa tus nombres y apellidos"
                      className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md sm:mb-0"
                      required
                    />
                  </div>
                  <div className="mx-0 mb-1 sm:mb-4">
                    <label
                      htmlFor="phone"
                      className="pb-1 text-xs uppercase tracking-wider"
                    >
                      Número de celular
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      autoComplete="tel"
                      placeholder="Ingresa tu número de celular"
                      className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md sm:mb-0"
                      required
                    />
                  </div>
                  <div className="mx-0 mb-1 sm:mb-4">
                    <label
                      htmlFor="email"
                      className="pb-1 text-xs uppercase tracking-wider"
                    >
                      Correo electrónico
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      autoComplete="email"
                      placeholder="Ingresa tu correo electrónico"
                      className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md sm:mb-0"
                      required
                    />
                  </div>
                </div>
                <div className="mx-0 mb-1 sm:mb-4">
                  <label
                    htmlFor="message"
                    className="pb-1 text-xs uppercase tracking-wider"
                  >
                    Ingrese un mensaje
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    cols="30"
                    rows="5"
                    placeholder="Escribe un mensaje..."
                    className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md sm:mb-0"
                  ></textarea>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="w-full bg-brown-800 hover:bg-brown-700 text-white px-6 py-3 font-xl rounded-md sm:mb-0 flex items-center justify-center"
                  >
                    <RiSendPlane2Fill className="mr-2" /> Enviar mensaje
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="relative w-full overflow-hidden pb-[56.25%]">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.3092592056237!2d-77.05972368974216!3d-12.090968188099833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c9ab48038e91%3A0x8e8c3efdee4f7781!2sAv.%20Faustino%20S%C3%A1nchez%20Carri%C3%B3n%20790%2C%20Magdalena%20del%20Mar%2015076!5e0!3m2!1ses-419!2spe!4v1729884833338!5m2!1ses-419!2spe"
            className="absolute top-0 left-0 w-full h-full"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Contact;
