import React from 'react';

// import image
import Image from '../assets/img/house-banner.png';

// import components
import Search from './Search';

const Banner = () => {
  return (
    <section className='h-full maxx-h-[640px] mb-8 xl:mb-24'>
<div className='flex flex-col lg:flex-row'>
  <div className='lg:ml-8 xl:ml-[135px] flex flex-col items-center lg:items-start text-center lg:text-left justify-center flex-1 px-4 lg:px-0'>
    <h1 className='text-gray-800 text-4xl lg:text-[60px] font-semibold leading-none mb-6'>
      <span className='text-brown-700'>Residencial</span> Gianna
    </h1>
    <p className='max-w-[875px] mb-8 text-lg'>
    Descubre un espacio que refleja tu estilo y se convierte en tu refugio. Comodidad, diseño y una comunidad vibrante te esperan. ¡Es el inicio de una nueva etapa!
    </p>

    <button
      onClick={() => window.open('https://kuula.co/share/5CL3k/collection/7csJb?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1', '_blank')}
      className='bg-brown-700 text-white font-light flex items-center py-4 px-4 mb-10 rounded transition duration-300 hover:bg-brown-600 lg:self-start'
    >
      Visualización en 360°
    </button>



        </div>
        <div className='hidden flex-1 lg:flex justify-end items-end'>
          <img src={Image} alt='' />
        </div>
      </div>
      <Search />
    </section>
  );
};

export default Banner;
