// import house images

import House1 from './assets/img/houses/house1.png';
import House2 from './assets/img/houses/house2.png';
import House3 from './assets/img/houses/house3.png';
import House4 from './assets/img/houses/house4.png';
import House5 from './assets/img/houses/house5.png';
import House6 from './assets/img/houses/house6.png';

// import house large images
import GiannaLg from './assets/img/houses/Gianna.jpg';
import SeoaneLg from './assets/img/houses/Seoane.jpg';
import House7Lg from './assets/img/houses/house7lg.png';
import House8Lg from './assets/img/houses/house8lg.png';

// import apartments large images
import Apartment1Lg from './assets/img/apartments/a1lg.png';
import Apartment3Lg from './assets/img/apartments/a3lg.png';

// import agents images

import Agent1 from './assets/img/agents/agent1.JPG';

// import brochures
import BrochureGianna from './assets/brochures/Brochure_Gianna.pdf';


export const housesData = [
  {
    id: 6,
    type: 'Nuevo Proyecto',
    name: 'Residencial Gianna',
    description:
      'El Proyecto Residencial Gianna es un edificio multifamiliar de 10 pisos que cuenta  con 24 departamentos. Está ubicado estratégicamente a solo 3 cuadras de la Plaza San Miguel y del centro comercial, así como a 3 cuadras del Colegio Juan XXIII y a 2 cuadras de la Clínica San Judas Tadeo. Además, se encuentra muy cerca de universidades destacadas como la Pontificia Universidad Católica del Perú, la Universidad San Marcos y la UPC. La ubicación es ideal, ya que estamos frente a un lindo parque, proporcionando un entorno agradable y accesible.',
    image: House1,  
    imageLg: GiannaLg,
    country: 'San Miguel',
    address: 'Calle Julio Verne 295 Urb. Pershing',
    bedrooms: 'De 2 a 4',
    bathrooms: 'De 2 a 3',
    surface: '303 m2',
    price: '560000',
    mapEmbed: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.463729086926!2d-77.08094372401995!3d-12.080373442529316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c90cc3fd7f23%3A0xbf7a9b7d71ce9093!2sJulio%20Verne%20295%2C%20San%20Miguel%2015086!5e0!3m2!1ses-419!2spe!4v1728588280044!5m2!1ses-419!2spe',
    vista360: 'https://kuula.co/share/5CL3v/collection/7csJb?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1',
    agent: {
      image: Agent1,
      name: 'Agente Inmobiliaria Segian',
    },
    services: [
      'Fachada con ladrillo rococho',
      'Lobby amplio',
      'Cocina encimera',
      'Cocina con muebles bajos y altos',
      'Vidrios templados',
      'Closets en dormitorios',
      'Cocina con piedra sinterizada',
      'Sensor de movimiento',
      'Sistema de monóxido de carbono',
      'Escaleras de evacuación',
      'Sistema de presión constante',
      'Conexión de gas natural',
      'Sistema contra incendios',
      'Baño con tablero de cuarzo',
    ],
    brochure: BrochureGianna,
  },
  {
    id: 5,
    type: 'Proyecto Entregado',
    name: 'Edificio Parque Kennedy',
    description:
      'El Edificio Parque Kennedy está ubicado en Parque Kennedy 140, Urbanización Suta, Pueblo Libre. Este moderno proyecto ha sido entregado, y actualmente no hay departamentos disponibles. Su ubicación estratégica, a solo 5 cuadras del Parque de los Próceres y cerca del centro comercial Plaza San Miguel, lo convierte en un lugar ideal para disfrutar de un entorno tranquilo y accesible.',
    image: House2,
    imageLg: Apartment1Lg,
    country: 'Pueblo Libre',
    address: 'Parque Kennedy 140 Urb. Suta',
    bedrooms: 'De 2 a 4',
    bathrooms: 'De 2 a 3',
    surface: '486 m2',
    price: '520000',
    mapEmbed: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3901.5011171828214!2d-77.0744455!3d-12.0778077!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDA0JzQwLjEiUyA3N8KwMDQnMjguMCJX!5e0!3m2!1ses-419!2spe!4v1728589828678!5m2!1ses-419!2spe',
    agent: {
      image: Agent1,
      name: 'Agente Inmobiliaria Segian',
    },
    services: [
      'Fachada con ladrillo rococho',
      'Lobby amplio',
      'Cocina encimera',
      'Cocina con muebles bajos y altos',
      'Vidrios templados',
      'Closets en dormitorios',
      'Cocina con piedra sinterizada',
      'Sensor de movimiento',
      'Sistema de monóxido de carbono',
      'Escaleras de evacuación',
      'Sistema de presión constante',
      'Conexión de gas natural',
      'Sistema contra incendios',
      'Baño con tablero de cuarzo',
    ],
  },
  {
    id: 4,
    type: 'Proyecto Entregado',
    name: 'Edificio Seoane',
    description:
      'El Edificio Seoane está ubicado en Jr. Manuel Seoane 178, Pueblo Libre. Este moderno proyecto ha sido entregado y actualmente no hay departamentos disponibles. Su ubicación es excelente, a pocos pasos del Parque Juan Pablo II y a solo 10 minutos del centro comercial Plaza San Miguel. Además, se encuentra cerca de reconocidas instituciones educativas como el Colegio San Ignacio de Loyola y la Universidad Nacional de San Marcos.',
    image: House3,
    imageLg: SeoaneLg,
    country: 'Pueblo Libre',
    address: 'Jr. Manuel Seoane 178',
    bedrooms: 'De 2 a 4',
    bathrooms: 'De 2 a 3',
    surface: '320 m2',
    price: '480000',
    mapEmbed: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3901.531180905466!2d-77.0721219!3d-12.0757442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDA0JzMyLjciUyA3N8KwMDQnMTkuNiJX!5e0!3m2!1ses-419!2spe!4v1728589793260!5m2!1ses-419!2spe',
    agent: {
      image: Agent1,
      name: 'Agente Inmobiliaria Segian',
    },
    services: [
      'Fachada con ladrillo rococho',
      'Lobby amplio',
      'Cocina encimera',
      'Cocina con muebles bajos y altos',
      'Vidrios templados',
      'Closets en dormitorios',
      'Cocina con piedra sinterizada',
      'Sensor de movimiento',
      'Sistema de monóxido de carbono',
      'Escaleras de evacuación',
      'Sistema de presión constante',
      'Conexión de gas natural',
      'Sistema contra incendios',
      'Baño con tablero de cuarzo',
    ],
  },
  {
    id: 3,
    type: 'Proyecto Entregado',
    name: 'Edificio Artigas',
    description:
      'El Edificio Artigas se encuentra en General Artigas 571, Pueblo Libre. Este moderno proyecto ha sido entregado y actualmente no hay departamentos disponibles. Su ubicación es muy conveniente, a solo unas cuadras del Parque Juan Pablo II y del Parque de los Próceres. Además, está cerca de instituciones educativas como el Colegio San Ignacio de Loyola y la Universidad Nacional de San Marcos. Rodeado de áreas verdes y con acceso a servicios esenciales, el Edificio Artigas ofrece un entorno agradable y accesible para sus residentes.',
    image: House4,
    imageLg: Apartment3Lg,
    country: 'Pueblo Libre',
    address: 'General Artigas 571',
    bedrooms: 'De 2 a 4',
    bathrooms: 'De 2 a 3',
    surface: '314 m2',
    price: '465000',
    mapEmbed: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3901.571914382346!2d-77.0626387!3d-12.072947800000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDA0JzIyLjYiUyA3N8KwMDMnNDUuNSJX!5e0!3m2!1ses-419!2spe!4v1728589754771!5m2!1ses-419!2spe',
    agent: {
      image: Agent1,
      name: 'Agente Inmobiliaria Segian',
    },
    services: [
      'Fachada con ladrillo rococho',
      'Lobby amplio',
      'Cocina encimera',
      'Cocina con muebles bajos y altos',
      'Vidrios templados',
      'Closets en dormitorios',
      'Cocina con piedra sinterizada',
      'Sensor de movimiento',
      'Sistema de monóxido de carbono',
      'Escaleras de evacuación',
      'Sistema de presión constante',
      'Conexión de gas natural',
      'Sistema contra incendios',
      'Baño con tablero de cuarzo',
    ],
  },
  {
    id: 2,
    type: 'Proyecto Entregado',
    name: 'Edificio Valle Riestra',
    description:
      'El Edificio Valle Riestra se encuentra en General José Ramón Pizarro 820, Pueblo Libre. Este proyecto ha sido entregado y actualmente no hay departamentos disponibles. Su ubicación es muy conveniente, a solo unas cuadras del Parque Juan Pablo II y cerca de la Avenida Brasil, lo que proporciona fácil acceso al transporte público. Además, está próximo a instituciones educativas como el Colegio Santa María y la Universidad Nacional de San Marcos.',
    image: House5,
    imageLg: House7Lg,
    country: 'Pueblo Libre',
    address: 'General José Ramón Pizarro 820',
    bedrooms: 'De 2 a 4',
    bathrooms: 'De 2 a 3',
    surface: '382 m2',
    price: '420000',
    mapEmbed: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3901.5312464617255!2d-77.06955719999999!3d-12.0757397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDA0JzMyLjciUyA3N8KwMDQnMTAuNCJX!5e0!3m2!1ses-419!2spe!4v1728589697643!5m2!1ses-419!2spe',
    agent: {
      image: Agent1,
      name: 'Agente Inmobiliaria Segian',
    },
    services: [
      'Fachada con ladrillo rococho',
      'Lobby amplio',
      'Cocina encimera',
      'Cocina con muebles bajos y altos',
      'Vidrios templados',
      'Closets en dormitorios',
      'Cocina con piedra sinterizada',
      'Sensor de movimiento',
      'Sistema de monóxido de carbono',
      'Escaleras de evacuación',
      'Sistema de presión constante',
      'Conexión de gas natural',
      'Sistema contra incendios',
      'Baño con tablero de cuarzo',
    ],
  },
  {
    id: 1,
    type: 'Proyecto Entregado',
    name: 'Edificio San Sebastián',
    description:
      'El Edificio San Sebastián se encuentra en Calle Felipe Santiago Ore 281-283, Urbanización Sucre, Pueblo Libre. Este proyecto ha sido entregado y actualmente no hay departamentos disponibles. Su ubicación es privilegiada, a solo unas cuadras del Parque de los Próceres y del Parque Juan Pablo II, ideales para actividades al aire libre. Además, está cerca del Mercado de Pueblo Libre, donde se pueden encontrar productos frescos y locales, y del Museo Nacional de Arqueología, Antropología e Historia del Perú. También se sitúa a pocos minutos de reconocidas instituciones educativas como el Colegio San José y la Universidad Nacional de San Marcos.',
    image: House6,
    imageLg: House8Lg,
    country: 'Pueblo Libre',
    address: 'Calle Felipe Santiago Ore 281 -283 Urb. Sucre',
    bedrooms: 'De 2 a 4',
    bathrooms: 'De 2 a 3',
    surface: '282 m2',
    price: '445000',
    mapEmbed: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3901.448075558329!2d-77.0694675!3d-12.0814475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDA0JzUzLjIiUyA3N8KwMDQnMTAuMSJX!5e0!3m2!1ses-419!2spe!4v1728589630744!5m2!1ses-419!2spe',
    agent: {
      image: Agent1,
      name: 'Agente Inmobiliaria Segian',
    },
    services: [
      'Fachada con ladrillo rococho',
      'Lobby amplio',
      'Cocina encimera',
      'Cocina con muebles bajos y altos',
      'Vidrios templados',
      'Closets en dormitorios',
      'Cocina con piedra sinterizada',
      'Sensor de movimiento',
      'Sistema de monóxido de carbono',
      'Escaleras de evacuación',
      'Sistema de presión constante',
      'Conexión de gas natural',
      'Sistema contra incendios',
      'Baño con tablero de cuarzo',
    ],
  },
];
