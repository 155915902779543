import React from 'react';
import LogoWhite from '../assets/img/logo_white.svg';

// import icons
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-brown-900">
      <div className="w-[90%] mx-auto p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a href="/" className="flex items-center justify-center">
              <img src={LogoWhite} className="h-16 me-3" alt="Inmobiliaria Segian" />
            </a>
          </div>
          <div className="grid text-center gap-8 sm:gap-20 sm:grid-cols-2">
            <div>
              <h2 className="mb-6 text-xl font-medium text-brown-400">Contáctanos</h2>
              <ul className="text-white font-light">
                <li className="mb-4">
                  <a href="tel:+51944898487" className="hover:underline">(+51) 944 898 487</a>
                </li>
                <li>
                  <a href="mailto:ventas@inmobiliariasegian.com.pe" className="hover:underline">ventas@inmobiliariasegian.com.pe</a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-xl font-medium text-brown-400">Horarios de atención</h2>
              <ul className="text-white font-light">
                <li className="mb-4">
                  <span>Lunes a Sábados de 9am a 6pm</span>
                </li>
                <li className="mb-4">
                  <span>Domingos y feriados de 9am a 12pm</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 sm:mx-autoborder-white lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between text-center">
          <span className="text-sm text-white sm:text-center">Inmobiliaria Segian © 2024. Todos los derechos reservados.</span>
          <div className="text-white flex mt-4 justify-center sm:justify-center sm:mt-0">
            <a href='https://www.facebook.com/p/Inmobiliaria-Segian-100063534583402/' target='_blank' rel="noopener noreferrer">
              <FaFacebook className='hover:text-brown-400 mr-5 text-2xl' />
            </a>
            <a href='https://www.instagram.com/inmobiliaria_segian/' target='_blank' rel="noopener noreferrer">
              <FaInstagram className='hover:text-brown-400 mr-5 text-2xl' />
            </a>
            <a href='https://api.whatsapp.com/send/?phone=51944898487' target='_blank' rel="noopener noreferrer">
              <FaWhatsapp className='hover:text-brown-400 text-2xl' />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
