import React, { useState } from 'react';

const FloatingContactForm = () => {
  // Cambia el estado inicial a true para que el formulario esté abierto al inicio
  const [isOpen, setIsOpen] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    message: ''
  });

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, phone, message } = formData;

    // Asegúrate de que el mensaje esté correctamente codificado
    const subject = encodeURIComponent(`Consulta de ${name}`);
    const body = encodeURIComponent(`Nombre: ${name}\nCelular: ${phone}\nMensaje: ${message}`);
    
    const mailtoLink = `mailto:ventas@inmobiliariasegian.com.pe?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;

    // Limpiar el formulario
    setFormData({ name: '', phone: '', message: '' });
    toggleForm();
  };

  return (
    <>
      {/* Oculta el botón solo si el formulario está abierto */}
      {!isOpen && (
        <button
          onClick={toggleForm}
          className="fixed top-1/2 right-0 transform -translate-y-1/2 bg-brown-700 text-white p-4 rounded-l-lg shadow-lg hover:bg-brown-600 transition h-[200px] w-[50px] flex items-center justify-center"
        >
          <span className="text-white font-semibold text-sm transform -rotate-90">SOLICITAR COTIZACIÓN</span>
        </button>
      )}

      {isOpen && (
        <div className="fixed top-1/2 right-12 transform -translate-y-1/2 bg-white border border-gray-400 p-8 shadow-lg rounded-lg w-80">
          <h2 className="text-xl font-bold mb-4 text-brown-700">Solicitar cotización</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block mb-2 text-sm text-gray-600">Nombre</label>
              <input
                type="text"
                name="name"
                className="w-full p-3 border border-gray-300 rounded"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-sm text-gray-600">Número celular</label>
              <input
                type="phone"
                name="phone"
                className="w-full p-3 border border-gray-300 rounded"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-sm text-gray-600">Mensaje</label>
              <textarea
                name="message"
                className="w-full p-3 border border-gray-300 rounded"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-brown-700 text-white p-3 rounded-lg hover:bg-brown-600 transition"
            >
              Cotizar
            </button>
          </form>

          <button
            onClick={toggleForm}
            className="absolute top-1/2 right-[-50px] transform -translate-y-1/2 bg-gray-500 text-white p-4 rounded-r-lg shadow-lg hover:bg-gray-600 transition h-[200px] w-[50px] flex items-center justify-center"
          >
            <span className="text-white font-semibold text-sm transform -rotate-90">OCULTAR COTIZACIÓN</span>
          </button>
        </div>
      )}
    </>
  );
};

export default FloatingContactForm;
