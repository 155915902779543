import React from "react";
// Import icons
import { RiPhoneLine } from "react-icons/ri";
import { FaBars, FaTimes } from "react-icons/fa"; // Importar iconos para el menú

// Import Link from react-router-dom
import { Link } from "react-router-dom";

// Import logo
import Logo from "../assets/img/logo.svg";

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false); // Estado para controlar el menú

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Alternar el estado del menú
  };

  return (
    <header className="py-6 mb-12 border-b">
      <div className="w-[90%] mx-auto flex justify-between items-center">
        {" "}
        {/* Ajusta el ancho a 90% */}
        {/* Logo */}
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
        {/* Menu toggle button */}
        <div className="md:hidden" onClick={toggleMenu}>
          {isOpen ? (
            <FaTimes className="text-2xl text-gray-900" />
          ) : (
            <FaBars className="text-2xl text-brown-900" />
          )}{" "}
          {/* Botón de menú */}
        </div>
        {/* Menú de escritorio */}
        <div className="hidden md:flex items-center gap-8">
          {" "}
          {/* Asegúrate de que este contenedor esté visible en vista de escritorio */}
          <Link className="hover:text-brown-900" to="/">
            Inicio
          </Link>
          <Link className="hover:text-brown-900" to="/about">
            Nosotros
          </Link>
          <Link className="hover:text-brown-900" to="/contact">
            Contacto
          </Link>
          <Link
            className="bg-brown-700 hover:bg-brown-600 text-white px-4 py-3 rounded-xl flex justify-center items-center"
            href="+51944898487"
          >
            <RiPhoneLine className="mr-2 text-xl" />
            Llámanos
          </Link>
        </div>
      </div>
      {/* Menú responsive */}
      <div
        className={`bg-white md:hidden ${
          isOpen ? "block" : "hidden"
        } transition-all duration-300`}
      >
        <div className="flex flex-col items-start gap-8 py-4 pl-8">
          <Link className="hover:text-brown-900" to="/">
            Inicio
          </Link>
          <Link className="hover:text-brown-900" to="/about">
            Nosotros
          </Link>
          <Link className="hover:text-brown-900" to="/contact">
            Contacto
          </Link>
          <a
            className="bg-brown-900 hover:bg-brown-700 text-white px-4 py-3 rounded-xl flex justify-center items-center"
            href="tel:+51944898487"
          >
            <RiPhoneLine className="mr-2 text-xl" />
            Llámanos
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
