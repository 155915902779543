import React from 'react';

// import routes and route
import { Routes, Route, useLocation } from 'react-router-dom';

// import components 
import Header from './components/Header';
import Footer from './components/Footer';
import FloatingContactForm from './components/FloatingContactForm';
import { FaWhatsapp } from 'react-icons/fa';

// import pages
import Home from './pages/Home';
import About from './components/About';
import Contact from './components/Contact';
import PropertyDetails from './pages/PropertyDetails';

const App = () => {
  const location = useLocation();

  return (
    <div>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/property/:id' element={<PropertyDetails />} />
      </Routes>

      {location.pathname === '/' && <FloatingContactForm />}

      <a
        href="https://api.whatsapp.com/send/?phone=51944898487"
        className="fixed bottom-5 right-5 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp size={30} />
      </a>

      <Footer />
    </div>
  );
};

export default App;
