import React, { useState, useEffect } from "react";
import { housesData } from "../data";
import { useParams } from "react-router-dom";
import { BiBed, BiBath, BiArea } from "react-icons/bi";

const formatPrice = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const PropertyDetails = () => {
  const { id } = useParams();
  const house = housesData.find((house) => house.id === parseInt(id));

  useEffect(() => {
    window.scrollTo(0, 0); // Desplaza la página hacia el inicio al cargar
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "Hola, estoy interesado(a) en adquirir un departamento del Proyecto ..."
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSendEmail = (e) => {
    e.preventDefault();
    const { name, email, phone, message } = formData;

    const subject = encodeURIComponent(`Consulta de ${name}`);
    const body = encodeURIComponent(`Nombre: ${name}\nCorreo: ${email}\nCelular: ${phone}\nMensaje: ${message}`);
    const mailtoLink = `mailto:ventas@inmobiliariasegian.com.pe?subject=${subject}&body=${body}`;
    
    window.location.href = mailtoLink;
  };

  return (
    <section>
      <div className="container mx-auto min-h-[800px] mb-14">
        <div
          className={`text-m text-white px-4 py-1 rounded-full inline-block mb-2 ${house.type === "Nuevo Proyecto" ? "bg-green-500" : "bg-gray-500"}`}
        >
          {house.type}
        </div>

        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
          <div>
            <h2 className="text-3xl font-semibold text-brown-600">{house.name}</h2>
            <h3 className="text-lg mb-4 text-gray-600">{house.address}, {house.country}</h3>
          </div>
          <div className="text-3xl font-semibold text-gray-800">
            Desde S/. {formatPrice(house.price)}
          </div>
        </div>

        <div className="flex flex-col items-start gap-8 lg:flex-row">
          <div className="max-w-[768px]">
            <div className="mb-8">
              <img src={house.imageLg} alt="" className="w-full rounded-lg" />
            </div>
            
            <div className="flex gap-x-6 text-brown-600 mb-6">
              <div className="flex gap-x-2 items-center">
                <BiBed className="text-xl" />
                <div className="text-gray-600 text-base">{house.bedrooms}</div>
              </div>
              <div className="flex gap-x-2 items-center">
                <BiBath className="text-xl" />
                <div className="text-gray-600 text-base">{house.bathrooms}</div>
              </div>
              <div className="flex gap-x-2 items-center">
                <BiArea className="text-xl" />
                <div className="text-gray-600 text-base">{house.surface}</div>
              </div>
            </div>

            <div className="text-m bg-brown-600 rounded-md text-white px-4 py-1 mb-4">Descripción</div>
            <div>{house.description}</div>
            <div className="text-m bg-brown-600 rounded-md text-white px-4 py-1 mt-4 mb-4">Servicios</div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              {house.services.map((service, index) => (
                <div key={index} className="text-gray-600">• {service}</div>
              ))}
            </div>

            <div className="text-m bg-brown-600 rounded-md text-white px-4 py-1 mt-4 mb-4">Ubicación</div>
            <div className="w-full">
              <div className="overflow-hidden rounded-lg">
                <iframe
                  src={house.mapEmbed}
                  width="100%"
                  height="450"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  title="Mapa"
                ></iframe>
              </div>
            </div>

            <div className="flex mt-6 space-x-4">
              {house.brochure && (
                <a href={house.brochure} target="_blank" rel="noopener noreferrer">
                  <button className="bg-brown-900 hover:bg-brown-700 text-white rounded p-4 text-sm">
                    Descargar Brochure
                  </button>
                </a>
              )}
              {house.vista360 && (
                <a href={house.vista360} target="_blank" rel="noopener noreferrer">
                  <button className="bg-brown-900 hover:bg-brown-700 text-white rounded p-4 text-sm">
                    Vista 360°
                  </button>
                </a>
              )}
            </div>
          </div>

          <div className="flex-1 bg-white w-full mb-8 border border-gray-300 rounded-lg px-6 py-8 lg:sticky lg:top-20">
            <div className="flex items-center gap-x-4 mb-8">
              <div className="w-32 h-32 overflow-hidden rounded-lg border border-brown-600 bg-gray-200">
                <img src={house.agent.image} alt={house.agent.name} className="object-cover w-full h-full" />
              </div>
              <div>
                <div className="font-bold text-lg">{house.agent.name}</div>
                <span className="text-brown-700 text-sm">Asesora de ventas</span>
              </div>
            </div>

            <form className="flex flex-col gap-y-4" onSubmit={handleSendEmail}>
              <input
                className="border border-gray-300 focus:border-orange-500 outline-none rounded w-full px-4 h-14 text-sm"
                type="text"
                name="name"
                placeholder="Nombres y apellidos*"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                className="border border-gray-300 focus:border-orange-500 outline-none rounded w-full px-4 h-14 text-sm"
                type="email"
                name="email"
                placeholder="Correo electrónico*"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input
                className="border border-gray-300 focus:border-orange-500 outline-none rounded w-full px-4 h-14 text-sm"
                type="text"
                name="phone"
                placeholder="Número celular*"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <textarea
                className="border border-gray-300 focus:border-orange-500 outline-none resize-none rounded w-full p-4 h-36 text-sm text-gray-400"
                name="message"
                placeholder="Ingresa un mensaje*"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              <div className="flex gap-x-2">
                <button type="submit" className="bg-brown-700 hover:bg-brown-600 text-white rounded p-4 text-sm w-full transition">
                  Enviar mensaje
                </button>
                <a
                  href="tel:+51944898487"
                  className="border border-brown-700 text-brown-700 hover:border-brown-600 hover:text-brown-600 rounded p-4 text-sm w-full text-center transition"
                >
                  Llamar
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropertyDetails;
