import React from 'react';

// import icons
import { BiBed, BiBath, BiArea } from 'react-icons/bi';

const House = ({ house }) => {
  const { image, type, country, address, bedrooms, bathrooms, surface, price, name } = house;

  // Formatear el precio con coma
  const formattedPrice = new Intl.NumberFormat('es-PE').format(price);

  return (
    <div className='border hover:border-brown-300 bg-white shadow-1 p-5 rounded-lg rounded-tl-[90px] rounded-tr-[90px] w-full max-w-[352px] mx-auto cursor-pointer hover:shadow-2xl transition'>
      <img className='mb-4' src={image} alt={address} />
      <div className='mb-4 flex gap-x-2 text-sm'>
        <div className={`rounded-full text-white px-3 ${type === 'Nuevo Proyecto' ? 'bg-green-500' : 'bg-gray-500'}`}>
          {type}
        </div>
        <div className='bg-brown-300 rounded-full text-white px-3'>{country}</div>
      </div>
      <div className='text-xl font-bold max-w-[260px] text-brown-600'>{name}</div>
      <div className='text-m font-normal max-w-[260px] truncate text-gray-600'>{address}</div>
      <div className='flex gap-x-4 my-4'>
        <div className='flex items-center text-brown-600 gap-1'>
          <div className='text-[20px]'>
            <BiBed />
          </div>
          <div className='text-gray-600'>{bedrooms}</div>
        </div>
        <div className='flex items-center text-brown-600 gap-1'>
          <div className='text-[20px]'>
            <BiBath />
          </div>
          <div className='text-gray-600'>{bathrooms}</div>
        </div>
        <div className='flex items-center text-brown-600 gap-1'>
          <div className='text-[20px]'>
            <BiArea />
          </div>
          <div className='text-gray-600'>{surface}</div>
        </div>
      </div>
      <div className='text-lg font-medium mb-4'>Desde S/. {formattedPrice}</div>
    </div>
  );
};

export default House;
