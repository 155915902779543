import React from "react";
import CountUp from "react-countup";

// import icons
import { FaStar, FaKey, FaBuilding } from "react-icons/fa";
import { FiTarget, FiEye } from "react-icons/fi";
import { GiEcology } from "react-icons/gi"
import { FaFaceGrin, FaBuildingColumns } from "react-icons/fa6";

const testimonialsData = [
  {
      quote: "Agradezco el esfuerzo de mi familia, de la comunidad y, especialmente, de INMOBILIARIA SEGIAN por brindarnos la oportunidad de disfrutar la hermosa experiencia de tener un departamento propio en Pueblo Libre.",
      name: "Carla Llorente",
      role: "Parque Kennedy",
      avatar: "https://w7.pngwing.com/pngs/915/511/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png"
  },
  {
      quote: "Después de 9 años en una casa alquilada, encontré INMOBILIARIA SEGIAN a través de redes sociales y amigos. Gracias a ellos, pude adquirir un departamento en Parque Seoane que se ajusta a mi economía. ¡Una experiencia muy positiva!",
      name: "Richard Rivera S.",
      role: "Edificio Seoane",
      avatar: "https://w7.pngwing.com/pngs/490/157/png-transparent-male-avatar-boy-face-man-user-flat-classy-users-icon-thumbnail.png"
  },
  {
      quote: "A las familias que aún están en alquiler, ¡anímense a buscar su propio hogar con INMOBILIARIA SEGIAN! Aunque el proceso lleve tiempo, la tranquilidad de tener un departamento propio y compartir momentos con los vecinos es una experiencia única y gratificante.",
      name: "Rafael Vargas",
      role: "Edificio Valle Riestra",
      avatar: "https://w7.pngwing.com/pngs/490/157/png-transparent-male-avatar-boy-face-man-user-flat-classy-users-icon-thumbnail.png"
  },
]

const About = () => {
  return (
    <>
      <div className="gap-16 items-center py-2 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-10 lg:px-6">
        <div className="font-normal text-gray-600 sm:text-lg">
          <h2 className="mb-4 text-5xl tracking-tight font-semibold text-brown-700">
            Inmobiliaria Segian
          </h2>
          <p className="mb-4">
            Nos hemos especializado en la edificación y promoción de proyectos de vivienda multifamiliar de baja densidad poblacional estratégicamente ubicados por su cercanía a áreas verdes, centros comerciales y recreacionales. 
          </p>
          <p>
            Contamos con más de 12 años dentro del sector inmobiliario, liderando las opciones que tienen las personas al momento de elegir un departamento.
          </p>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-8">
          <img
            className="w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png"
            alt="office content 1"
            loading="lazy"
          />
          <img
            className="mt-4 w-full lg:mt-10 rounded-lg"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png"
            alt="office content 2"
            loading="lazy"
          />
        </div>
      </div>
{/* Nuestra historia */}
      <section className="text-gray-600 body-font font-normal">
        <div className="container px-5 py-20 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-3xl text-4xl font-semibold title-font mb-4 text-brown-700">
              Nuestra historia
            </h1>
            <p className="mt-4 text-gray-500 xl:mt-6">
              Somos una empresa joven que combina la experiencia de nuestro equipo profesional con modernos e innovadores proyectos que se caracterizan por buscar la armonía estética, el confort del usuario y una alta calidad constructiva.
            </p>
          </div>
          <div className="flex flex-wrap -m-4 text-center">
            <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
              <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                <FaStar className="text-brown-700 w-12 h-12 mb-3 inline-block" />
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  +<CountUp start={0} end={10} duration={2.8} />
                </h2>
                <p className="leading-relaxed">Años de experiencia</p>
              </div>
            </div>
            <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
              <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                <FaKey className="text-brown-700 w-12 h-12 mb-3 inline-block" />
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  +<CountUp start={0} end={5} duration={2.8} />
                </h2>
                <p className="leading-relaxed">Proyectos entregados</p>
              </div>
            </div>
            <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
              <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                <FaBuilding className="text-brown-700 w-12 h-12 mb-3 inline-block" />
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  +<CountUp start={0} end={80} duration={2.8} />
                </h2>
                <p className="leading-relaxed">Departamentos</p>
              </div>
            </div>
            <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
              <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                <FaFaceGrin className="text-brown-700 w-12 h-12 mb-3 inline-block" />
                <h2 className="title-font font-medium text-3xl text-gray-900">
                  +<CountUp start={0} end={267} duration={2.8} />
                </h2>
                <p className="leading-relaxed">Clientes satisfechos</p>
              </div>
            </div>
          </div>
        </div>
      </section>
{/* Fin Nuestra historia  */}
{/* inicio valores */}
<section className="bg-white">
    <div className="container px-6 py-10 mx-auto text-center">
        <h1 className="sm:text-3xl text-4xl font-semibold title-font mb-4 text-brown-700">
            Nuestros Valores
        </h1>

        <p className="mt-4 text-gray-500 xl:mt-6">
            En Inmobiliaria Segian, nuestros valores fundamentales guían cada acción. Nos comprometemos a promover el respeto y la responsabilidad hacia las personas y el medio ambiente.
        </p>

        <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 lg:grid-cols-2"> 
            <div className="p-8 space-y-3 border-2 border-brown-700 rounded-xl">
                <span className="inline-block text-brown-700">
                    <FiTarget className="w-8 h-8" />
                </span>

                <h1 className="text-xl font-semibold text-gray-700 capitalize">Nuestra misión</h1>

                <p className="text-gray-500">
                  Desarrollar proyectos que satisfagan las necesidades y expectativas; basados en un buen producto, y la satisfacción de nuestros clientes.
                </p>
            </div>

            <div className="p-8 space-y-3 border-2 border-brown-700 rounded-xl">
                <span className="inline-block text-brown-700">
                    <FiEye className="w-8 h-8" />
                </span>

                <h1 className="text-xl font-semibold text-gray-700 capitalize">Nuestra Visión</h1>

                <p className="text-gray-500">
                  Ser la empresa constructora líder en el sector inmobiliario, cuyos productos y servicios se identifiquen con un alto estándar de calidad.
                </p>
            </div>

            <div className="p-8 space-y-3 border-2 border-brown-700 rounded-xl">
                <span className="inline-block text-brown-700">
                    <FaBuildingColumns className="w-8 h-8" />
                </span>

                <h1 className="text-xl font-semibold text-gray-700 capitalize">Nuestro Pilar</h1>

                <p className="text-gray-500 ">
                  Todos los proyectos, ofrecen estándares de calidad, garantizando seguridad y comodidad para sus clientes basados en nuestros cuatro pilares de marca.
                </p>
            </div>

            <div className="p-8 space-y-3 border-2 border-brown-700 rounded-xl">
                <span className="inline-block text-brown-700">
                    <GiEcology className="w-8 h-8" />
                </span>

                <h1 className="text-xl font-semibold text-gray-700">Compromiso y Respeto Ambiental</h1>

                <p className="text-gray-500 ">
                  La responsabilidad y es respeto ambiental para que todo lo que logremos,pueda contribuir tambien al cuidado del medio ambiente.
                </p>
            </div>
        </div>
    </div>
</section>
{/* fin valores */}
{/* testimonials */}
<section className="py-12 bg-white sm:py-16 lg:py-20">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 ">
                <div className="flex flex-col items-center ">
                    <div className="text-center ">
                        <h2 className="text-4xl font-semibold text-brown-700 sm:text-4xl xl:text-4xl">Nuestros clientes</h2>
                        <p className="mt-4 font-small text-base text-gray-600">+de 250 clientes satisfechos</p>
                    </div>

                    <div className="relative mt-10 md:mt-24 ">
                        <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6 ">
                            <div className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter" style={{ background: 'linear-gradient(90deg, #8b44ff -0.55%, #8b44ff 22.86%, #8b44ff 48.36%, #8b44ff 73.33%, #8b44ff 99.34%) ' }}></div>
                        </div>

                        <div className="relative grid max-w-lg grid-cols-1 gap-6 mx-auto md:max-w-none lg:gap-10 md:grid-cols-3 ">
                            {testimonialsData.map((testimonial, index) => (
                                <div key={index} className="flex flex-col overflow-hidden shadow-xl border border-gray-400 hover:border-brown-700">
                                    <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                                        <div className="flex-1">
                                            <div className="flex items-center">
                                                {Array(5).fill(0).map((_, i) => (
                                                    <svg key={i} className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                    </svg>
                                                ))}
                                            </div>
                                            <blockquote className="flex-1 mt-8">
                                                <p className="text-lg leading-relaxed text-gray-900">“{testimonial.quote}”</p>
                                            </blockquote>
                                        </div>
                                        <div className="flex items-center mt-8">
                                            <img className="flex-shrink-0 object-cover rounded-full w-11 h-11" src={testimonial.avatar} alt="" />
                                            <div className="ml-4">
                                                <p className="text-base font-bold text-gray-900">{testimonial.name}</p>
                                                <p className="mt-0.5 text-sm text-gray-600">{testimonial.role}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>

{/* end testimonials */}
    </>
  );
};

export default About;
